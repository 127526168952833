'use strict';

import { request, gql } from 'graphql-request';
import { AccessCode } from '@/../../@types/api';
import { ACCESS_CODE_GRAPHQL_URL } from '@/constants/constant';

export const getAccessCode = async (args: { accessCode: string }): Promise<AccessCode> => {
  const query = gql`
    query {
      getAccessCode (accessCode: "${args.accessCode}"){
        id
        stripeId
        accessCode
        remainingNumber
        using
      }
    }
  `;
  const res = (async () => {
    try {
      return (await request(ACCESS_CODE_GRAPHQL_URL, query)).getAccessCode;
    } catch (e) {
      return `${e}`;
    }
  })();
  return res;
};

export const getAccessCodeList = async (): Promise<AccessCode[]> => {
  const query = gql`
    query {
      getAccessCodeList {
        accessCodeList {
          id
          stripeId
          accessCode
          remainingNumber
          using
        }
      }
    }
  `;
  const res = (async () => {
    try {
      return (await request(ACCESS_CODE_GRAPHQL_URL, query)).getAccessCodeList.accessCodeList;
    } catch (e) {
      return `${e}`;
    }
  })();
  return res;
};

export const upsertAccessCode = async (args: AccessCode): Promise<AccessCode> => {
  const idQuery = args.id !== -1 ? `id: ${args.id},` : '';
  const query = gql`
    mutation {
      upsertAccessCode(
        ${idQuery}
        stripeId: ${args.stripeId},
        accessCode: "${args.accessCode}",
        remainingNumber: ${args.remainingNumber},
        ) {
        ...upsertAccessCodeFields
      }
    }
    fragment upsertAccessCodeFields on AccessCode {
      id
      stripeId
      accessCode
      remainingNumber
      using
    }
  `;
  const res = async () => {
    try {
      return (await request(ACCESS_CODE_GRAPHQL_URL, query)).upsertAccessCode;
    } catch (e) {
      return `${e}`;
    }
  };
  return res();
};

export const destroyAccessCode = async (args: { id: number }): Promise<AccessCode> => {
  const query = gql`
    mutation {
      upsertAccessCode(
        id: ${args.id},
        using: false
        ) {
        ...upsertAccessCodeFields
      }
    }
    fragment upsertAccessCodeFields on AccessCode {
      using
    }
  `;
  const res = async () => {
    try {
      return (await request(ACCESS_CODE_GRAPHQL_URL, query)).upsertAccessCode;
    } catch (e) {
      return `${e}`;
    }
  };
  return res();
};
