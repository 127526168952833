'use strict';

import { request, gql } from 'graphql-request';
import { VideoData, QueryGetVideoDataArgs, QueryGetVideoDataListArgs } from '@/../../@types/api';

import axios from 'axios';
import { AxiosResponse } from 'axios';
import sortBy from 'lodash.sortby';
import {
  VIDEO_GRAPHQL_URL,
  VIDEO_S3_PRESIGNED_FOR_PUT_URL,
  VIDEO_S3_PRESIGNED_FOR_GET_URL,
  VIDEO_S3_DELETE_URL,
  VIDEO_S3_USED_FILE_SIZE_URL,
} from '@/constants/constant';

export const getVideoData = async (args: QueryGetVideoDataArgs): Promise<VideoData> => {
  const query = gql`
    query {
      getVideoData (
        ${args.imgSrc ? `imgSrc: "${args.imgSrc}"` : ''}
        ${args.videoViewSrc ? `videoViewSrc: "${args.videoViewSrc}"` : ''}
        ${args.videoS3Path ? `videoS3Path: "${args.videoS3Path}"` : ''}
        ${args.value ? `value: "${args.value}"` : ''}
        ${args.description ? `description: "${args.description}"` : ''}
      ){
        id
        imgSrc
        videoViewSrc
        videoS3Path
        value
        description
        release
      }
    }
  `;
  const res = (async () => {
    try {
      return (await request(VIDEO_GRAPHQL_URL, query)).getVideoData;
    } catch (e) {
      throw new Error(`${e}`);
    }
  })();
  return res;
};

export const getVideoDataList = async (args?: QueryGetVideoDataListArgs): Promise<VideoData[]> => {
  const query = gql`
    query {
      getVideoDataList ${args ? '(' : ''}
      ${args?.release ? `release: ${args.release}` : ''}
      ${args ? ')' : ''} {
        videoDataList {
          id
          imgSrc
          videoViewSrc
          videoS3Path
          value
          courseId
          description
          release
        }
      }
    }
  `;
  const res = await request(VIDEO_GRAPHQL_URL, query, undefined, {
    withCredentials: 'true',
  });
  return sortBy(res.getVideoDataList.videoDataList, ['value'], ['asc']);
};

export const getVideoDataListForCustomer = async (): Promise<VideoData[]> => {
  const query = gql`
    query {
      getVideoDataListForCustomer {
        videoDataList {
          id
          imgSrc
          videoViewSrc
          videoS3Path
          value
          courseId
          description
          release
        }
      }
    }
  `;
  const res = await request(VIDEO_GRAPHQL_URL, query);
  if (res.getVideoDataListForCustomer?.videoDataList) {
    return sortBy(res.getVideoDataListForCustomer.videoDataList, ['value'], ['asc']);
  }
  return [];
};

export const upsertVideoData = async (args: {
  id?: number;
  imgStr?: string;
  videoViewSrc?: string;
  videoS3Path?: string;
  value?: string;
  courseId?: number;
  description?: string;
  release?: boolean;
}): Promise<VideoData | string> => {
  const idQuery = args.id ? `id: ${args.id}` : '';
  const imgStrQuery = args.imgStr ? `imgSrc: "${args.imgStr}"` : '';
  const videoS3PathQuery = args.videoS3Path ? `videoS3Path: "${args.videoS3Path}"` : '';
  const videoViewSrcQuery = args.videoViewSrc ? `videoViewSrc: "${args.videoViewSrc}"` : '';
  const valueQuery = args.value ? `value: "${args.value}"` : '';
  const courseIdQuery = args.courseId ? `courseId: ${args.courseId}` : '';
  const descriptionQuery = args.description ? `description: "${args.description}"` : '';
  const releaseQuery = typeof args.release === 'boolean' ? `release: ${args.release}` : '';
  const query = gql`
    mutation {
      upsertVideoData(
        ${idQuery}
        ${imgStrQuery}
        ${videoS3PathQuery}
        ${videoViewSrcQuery}
        ${valueQuery}
        ${courseIdQuery}
        ${descriptionQuery}
        ${releaseQuery}
      ) {
        id
        imgSrc
        videoViewSrc
        videoS3Path
        value
        courseId
        description
        release
      }
    }
  `;
  try {
    return (await request(VIDEO_GRAPHQL_URL, query)).upsertVideoData;
  } catch (e) {
    return `${e}`;
  }
};

export const destroyVideoData = async (id: number): Promise<VideoData | string> => {
  const query = gql`
    mutation {
      upsertVideoData(
        id: ${id},
        using: false
        ) {
        ...VideoDataFields
      }
    }
    fragment VideoDataFields on VideoData {
      using
    }
  `;
  try {
    return (await request(VIDEO_GRAPHQL_URL, query)).upsertVideoData;
  } catch (e) {
    return `${e}`;
  }
};

/**
 * 実際のファイル名とは関係なく PUT 用パス取得で指定した名前で格納される
 * 既存ファイルと同じファイル名を指定してアップロードした場合上書きされる
 */
export const presignedUrlForPut = async (args: {
  fileName: string;
  bucket: 'public' | 'private';
}): Promise<{ s3_path: string; file_name: string }> => {
  const res = await axios.post(VIDEO_S3_PRESIGNED_FOR_PUT_URL, {
    fileName: args.fileName,
    bucket: args.bucket,
  });
  return res.data;
};

export const presignedUrlForGet = async (args: { fileName: string; bucket: 'public' | 'private' }): Promise<string> => {
  const res = await axios.post(VIDEO_S3_PRESIGNED_FOR_GET_URL, {
    fileName: args.fileName,
    bucket: args.bucket,
  });
  return res.data.s3_path;
};

export const uploadPresignedUrl = async (args: { url: string; file: File }): Promise<AxiosResponse> => {
  const res = await axios.put(args.url, args.file, { headers: { 'content-type': args.file.type } });
  return res;
};

export const s3Delete = async (args: {
  fileName: string;
  bucket: 'public' | 'private';
}): Promise<{ result: string }> => {
  const res = await axios.post(VIDEO_S3_DELETE_URL, args);
  return res.data;
};

export const usedFileSize = async (args: { bucket: 'public' | 'private' }): Promise<{ result: string }> => {
  const res = await axios.post(VIDEO_S3_USED_FILE_SIZE_URL, args);
  return res.data.result;
};
