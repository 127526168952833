
import { defineComponent, reactive } from 'vue';
import { useRouter } from 'vue-router';
import Thumbnail from '@/components/Thumbnail.vue';
import { VideoData } from '@/../../@types/api';
import { getCourseList } from '@/api/course';
import { Course, Stripe } from '@/../../@types/api';
import { getVideoDataListForCustomer } from '@/api/video';
import { getValidReceiptList } from '@/api/receipt';
import { getUserBySession } from '@/api/user';
import { getStripeList } from '@/api/stripe';
import { BUTTON_COLOR } from '@/constants/constant';
import { Search as SearchIcon, User, Tickets } from '@element-plus/icons-vue';
import VueScrollTo from 'vue-scrollto';

const planType = {
  茶道: { valName: 'tea' },
  和菓子: { valName: 'sweets' },
  着付け: { valName: 'kimono' },
};

type PlanCategoryName = keyof typeof planType;

export default defineComponent({
  name: 'Search',
  components: {
    User,
    Tickets,
    SearchIcon,
    Thumbnail,
  },
  async setup() {
    const router = useRouter();

    const videoDataList = await getVideoDataListForCustomer();
    const user = await getUserBySession();
    const state = reactive<{
      courseList: Course[];
      courseSelectorShowFlag: boolean;
      videoDataList: VideoData[];
      activeVideoDataList: VideoData[];
      autoCompleteValue: string;
      validPlan: { tea: Stripe[]; sweets: Stripe[]; kimono: Stripe[] };
    }>({
      courseList: await getCourseList(),
      courseSelectorShowFlag: false,
      videoDataList: videoDataList,
      activeVideoDataList: videoDataList,
      autoCompleteValue: '',
      validPlan: {
        tea: [],
        sweets: [],
        kimono: [],
      },
    });

    const stripePlanList = await getStripeList();
    const validReceiptList = await getValidReceiptList({ userId: Number(user.id) });
    validReceiptList.filter((receipt) => {
      stripePlanList.filter((stripe) => {
        if (Number(receipt.planId) === stripe.id) {
          const titleMatch = stripe.title?.match(/茶道|和菓子|着付け/gi);
          if (titleMatch) {
            const valName = planType[titleMatch[0] as PlanCategoryName].valName as 'tea' | 'sweets' | 'kimono';
            state.validPlan[valName].push(stripe);
          }
        }
      });
    });

    const activeCourseChange = (course: Course) => {
      state.courseSelectorShowFlag = false;
      VueScrollTo.scrollTo('#thumbnailListAnchor');
      state.activeVideoDataList = state.videoDataList.filter((videoData) => {
        return !!(videoData.courseId === course.id);
      });
    };

    // videoDataList から videoData が一件ずつ渡される
    // return !!(title.match(inputStr)) みたいな感じ
    const createFilter = (queryString: string) => {
      return (videoData: VideoData) => {
        return !!videoData.value?.toLowerCase().match(queryString.toLowerCase());
      };
    };

    const querySearch = (queryString: string, cb: (data: VideoData[]) => void) => {
      /**
       * result = (() => {
       *   if (inputへの入力 !== '') {
       *     videoData を一件ずつ createFilter に渡す
       *     return ↑でできたタイトルと input が一致したものだけの配列
       *   } else if (inputへの入力 === '') {
       *     return videoDataList 全部の配列
       *   }
       * })
       */
      const results = queryString
        ? state.activeVideoDataList.filter(createFilter(queryString))
        : state.activeVideoDataList;
      // 渡された配列に入ってるものをサジェスト表示
      cb(results);
    };

    const moveToVideo = (item: VideoData) => {
      router.push(`/video/view/${item.videoViewSrc}`);
      return;
    };

    const moveToSearchSP = () => {
      router.push(`/video/search/sp`);
      return;
    };

    const moveToMypage = () => {
      router.push(`/user/mypage`);
      return;
    };

    const search = () => {
      state.activeVideoDataList = state.videoDataList.filter((videoData) => {
        return !!videoData.value?.match(state.autoCompleteValue);
      });
    };

    const toggleCourseSelector = () => {
      state.courseSelectorShowFlag = !state.courseSelectorShowFlag;
      if (state.courseSelectorShowFlag) {
        window.scrollTo(0, 0);
      }
    };

    return {
      state,
      router,
      activeCourseChange,
      querySearch,
      moveToVideo,
      moveToSearchSP,
      moveToMypage,
      search,
      BUTTON_COLOR,
      stripePlanList,
      videoDataList,
      toggleCourseSelector,
    };
  },
});
