
import { defineComponent, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { Loading } from '@element-plus/icons-vue';
import * as notification from '@/components/_lib/notification';
import { AGREEMENT_URL, SITE_TITLE, BUTTON_COLOR } from '@/constants/constant';
import { createUser } from '@/api/user';
import { User } from '@/../../@types/api';

export default defineComponent({
  name: 'UserCreate',
  components: {
    Loading,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      siteTitle: SITE_TITLE,
      newUser: {
        name: '',
        email: '',
        pass: '',
        confirmationPass: '',
        agree: false,
      },
      sending: false,
    });
    const onSubmit = async () => {
      if (state.newUser.name === '') {
        notification.error({ title: 'エラー', message: '名前を入力して下さい' });
        return;
      }
      if (state.newUser.email === '') {
        notification.error({ title: 'エラー', message: 'メールアドレスを入力して下さい' });
        return;
      }
      if (state.newUser.pass.trim() === '') {
        notification.error({ title: 'エラー', message: 'パスワードを入力して下さい' });
        return;
      }
      if (state.newUser.confirmationPass.trim() === '') {
        notification.error({ title: 'エラー', message: 'パスワードを再度入力して下さい' });
        return;
      }
      if (state.newUser.agree === false) {
        notification.error({ title: 'エラー', message: '利用規約に同意して下さい' });
        return;
      }
      if (state.newUser.pass.trim() !== state.newUser.confirmationPass.trim()) {
        notification.error({ title: 'エラー', message: 'パスワードが一致しません' });
        return;
      }

      state.sending = true;
      const createdUser: User = await createUser({
        name: state.newUser.name,
        email: state.newUser.email,
        pass: state.newUser.pass,
      });
      state.sending = false;

      if (createdUser) {
        notification.success({ title: 'アカウントが作成されました', message: '認証メールを確認してください' });
        setTimeout(() => {
          router.push('/');
        }, 3000);
      } else {
        notification.error({ title: 'エラー', message: 'エラーです' });
      }
    };

    const agreementUrl = reactive({ AGREEMENT_URL });
    return {
      state,
      onSubmit,
      agreementUrl,
      BUTTON_COLOR,
    };
  },
});
