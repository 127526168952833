
import { defineComponent, reactive } from 'vue';
import ThumbnailForManagement from '@/components/ThumbnailForManagement.vue';
import VideoDataForm from '@/components/modules/VideoDataForm.vue';
import * as notification from '@/components/_lib/notification';
import { getVideoDataList, upsertVideoData, destroyVideoData, s3Delete, usedFileSize } from '@/api/video';
import { getStripeList } from '@/api/stripe';
import { getCourseList } from '@/api/course';
import { getStripeToVideoList, upsertStripeToVideo, destroyStripeToVideo } from '@/api/stripeToVideo';
import { BUTTON_COLOR, ENDPOINT } from '@/constants/constant';

import { VideoData } from '@/../../@types/api';

// element-plus
import { CaretTop, CaretBottom } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'ManagementVideoEdit',
  components: {
    VideoDataForm,
    ThumbnailForManagement,
    CaretTop,
    CaretBottom,
  },
  async setup(props, context) {
    const state = reactive({
      videoDataList: await getVideoDataList(),
      courseList: await getCourseList(),
      stripePlanList: await getStripeList(),
      videoDataRaw: {
        id: -1,
        value: '',
        description: '',
        videoViewSrc: '',
        courseId: -1,
        release: true,
      },
      selectPlan: [false],
      selectDestroyVideo: [],
      publicBucketInUse: Number(await usedFileSize({ bucket: 'public' })),
      privateBucketInUse: Number(await usedFileSize({ bucket: 'private' })),
      bucketFull: 1000,
    });

    const refresh = async () => {
      state.videoDataList = await getVideoDataList();
      state.courseList = await getCourseList();
      state.stripePlanList = await getStripeList();
      state.videoDataRaw.id = -1;
      state.videoDataRaw.value = '';
      state.videoDataRaw.description = '';
      state.videoDataRaw.videoViewSrc = '';
      state.videoDataRaw.courseId = -1;
      state.videoDataRaw.release = true;
      state.selectPlan = [false];
    };

    // emit
    const formChange = (args: {
      videoDataRaw: { value: string; description: string; videoViewSrc: string; courseId: number; release: boolean };
      selectPlan: Array<boolean>;
    }) => {
      state.videoDataRaw.value = args.videoDataRaw.value;
      state.videoDataRaw.description = args.videoDataRaw.description;
      state.videoDataRaw.videoViewSrc = args.videoDataRaw.videoViewSrc;
      state.videoDataRaw.courseId = args.videoDataRaw.courseId;
      state.videoDataRaw.release = args.videoDataRaw.release;
      state.selectPlan = args.selectPlan;
    };

    const editAreaOpen = async (editVideoDataId: number) => {
      const editVideoData = state.videoDataList.filter((videoData: VideoData) => {
        if (Number(videoData.id) === editVideoDataId) return true;
      })[0];
      const stripeToVideoList = await getStripeToVideoList({ videoId: Number(editVideoData.id) });
      state.selectPlan = [];
      for (const plan of stripeToVideoList) {
        state.selectPlan[Number(plan.stripeId)] = true;
      }
      state.videoDataRaw = {
        id: Number(editVideoData.id),
        value: String(editVideoData.value),
        description: String(editVideoData.description),
        videoViewSrc: String(editVideoData.videoViewSrc),
        courseId: Number(editVideoData.courseId),
        release: Boolean(editVideoData.release),
      };
    };

    const editSubmit = async () => {
      // videoData アップデート
      const editedVideoData = await (async () => {
        try {
          return await upsertVideoData({
            id: state.videoDataRaw.id,
            value: state.videoDataRaw.value,
            description: state.videoDataRaw.description,
            courseId: state.videoDataRaw.courseId,
            videoViewSrc: state.videoDataRaw.videoViewSrc,
            release: state.videoDataRaw.release,
          });
        } catch (e) {
          return `${e}`;
        }
      })();
      if (typeof editedVideoData === 'string') {
        notification.error({ title: 'エラー', message: '更新に失敗しました' });
        return;
      }
      notification.success({ title: '更新成功', message: '動画情報の更新成功しました' });
      // プラン削除
      const stripeToVideoList = await getStripeToVideoList({ videoId: state.videoDataRaw.id });
      for (const plan of stripeToVideoList) {
        try {
          await destroyStripeToVideo(Number(plan.id));
        } catch {
          notification.error({ title: 'エラー', message: `プランの削除に失敗しました` });
          return;
        }
      }
      // プラン作り直し
      const selectedPlanList = state.stripePlanList.filter((plan) => {
        return !!state.selectPlan[Number(plan.id)];
      });
      for (const plan of selectedPlanList) {
        try {
          await upsertStripeToVideo({ stripeId: Number(plan.id), videoId: state.videoDataRaw.id });
        } catch {
          notification.error({ title: 'エラー', message: `プランの作成に失敗しました` });
          return;
        }
      }
      notification.success({ title: '更新成功', message: 'プランの更新成功しました' });
      context.emit('refresh');
    };

    const destroy = async (videoData: VideoData) => {
      // videoData の削除
      const deletedVideoData = await (async () => {
        try {
          return await destroyVideoData(Number(videoData.id));
        } catch (e) {
          return `${e}`;
        }
      })();
      if (typeof deletedVideoData === 'string') {
        notification.error({ title: 'エラー', message: '削除に失敗しました' });
        return;
      }
      notification.success({ title: '削除成功', message: '動画情報の削除に成功しました' });
      // プランの削除
      const stripeToVideoList = await getStripeToVideoList({ videoId: Number(videoData.id) });
      for (const plan of stripeToVideoList) {
        try {
          await destroyStripeToVideo(Number(plan.id));
        } catch {
          notification.error({ title: 'エラー', message: `プランの削除に失敗しました` });
          return;
        }
      }
      notification.success({ title: '削除成功', message: 'プランの削除に成功しました' });

      const videoDeleteResult = await s3Delete({ bucket: 'public', fileName: String(videoData.imgSrc) });
      if (videoDeleteResult.result === 'success') {
        notification.success({ title: '削除成功', message: '動画の削除に成功しました' });
      } else {
        notification.error({ title: 'エラー', message: '動画の削除に失敗しました' });
      }
      const imgDeleteResult = await s3Delete({ bucket: 'private', fileName: String(videoData.videoS3Path) });
      if (imgDeleteResult.result === 'success') {
        notification.success({ title: '削除成功', message: 'サムネイルの削除に成功しました' });
      } else {
        notification.error({ title: 'エラー', message: 'サムネイルの削除に失敗しました' });
      }
      context.emit('refresh');
    };

    const getCourse = (videoData: VideoData) => {
      return state.courseList.filter((course) => {
        return !!(course.id === Number(videoData.courseId));
      })[0].title;
    };

    const destroyList = async () => {
      for (const videoId in state.selectDestroyVideo) {
        const destroyVideoData = state.videoDataList.filter((video) => {
          return !!(video.id === Number(videoId));
        })[0];
        await destroy(destroyVideoData);
      }
      state.selectDestroyVideo = [];
    };

    const releaseToggle = async (videoData: VideoData) => {
      const res = await upsertVideoData({
        id: Number(videoData.id),
        release: Boolean(videoData.release),
      });
      if (typeof res === 'string' || res === null) {
        notification.error({ title: 'エラー', message: '公開設定の変更に失敗しました' });
        return;
      }
      notification.success({ title: '成功', message: '公開設定を変更しました' });
      context.emit('refresh');
    };

    return {
      state,
      BUTTON_COLOR,
      ENDPOINT,
      refresh,
      formChange,
      editAreaOpen,
      editSubmit,
      destroy,
      getCourse,
      destroyList,
      releaseToggle,
    };
  },
});
