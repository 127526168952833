
import { defineComponent, reactive } from 'vue';
import { Stripe } from '@/../../@types/api';
import { getStripeList } from '@/api/stripe';
import { getAccessCode } from '@/api/accessCode';
import { getUserBySession } from '@/api/user';
import { getValidReceiptList, getPaidStatus } from '@/api/receipt';
import * as notification from '@/components/_lib/notification';
import SubscriptionModule from '@/components/modules/SubscriptionModule.vue';
import { BUTTON_COLOR } from '@/constants/constant';

export default defineComponent({
  name: 'Subscription',
  components: {
    SubscriptionModule,
  },
  async setup() {
    type planText =
      | '和菓子一ヶ月無料トライアル'
      | '和菓子初級'
      | '和菓子中級'
      | '和菓子上級'
      | '着付け一ヶ月無料トライアル'
      | '着付け初級'
      | '着付け中級'
      | '着付け上級'
      | '茶道一ヶ月無料トライアル'
      | '茶道初級'
      | '茶道中級'
      | '茶道上級'
      | '和菓子月額'
      | '着付け月額'
      | '茶道月額'
      | '経験者向け和菓子'
      | '経験者向け着付け'
      | '経験者向け茶道';

    const planType = {
      茶道: { valName: 'tea' },
      和菓子: { valName: 'sweets' },
      着付け: { valName: 'kimono' },
    };

    const planLevel = {
      トライアル: { level: 0 },
      初級: { level: 1 },
      中級: { level: 2 },
      上級: { level: 3 },
      月額: { level: 4 },
      経験者: { level: 5 },
    };

    const user = await getUserBySession();

    const state = reactive({
      paidHistory: await getPaidStatus(Number(user.id)),
      user,
      paid: {
        sweets: [false, false, false, false, false, false],
        kimono: [false, false, false, false, false, false],
        tea: [false, false, false, false, false, false],
      },
      accessCode: '',
      planDataFromAccessCode: {} as {
        plan: Stripe;
        paidNow: boolean;
        status: string;
        accessCode: string;
      },
    });

    const planList = await getStripeList();

    const validReceiptList = await getValidReceiptList({ userId: Number(user.id) });
    validReceiptList.filter((receipt) => {
      const plan = planList.filter((plan) => {
        return Number(plan.id) === Number(receipt.planId);
      })[0];
      const planTypeStr = plan?.title?.match(/茶道|和菓子|着付け/gi);
      const planLevelStr = plan?.title?.match(/トライアル|初級|中級|上級|月額|経験者/gi);
      if (!planTypeStr || !planLevelStr) return false;
      const type = planType[planTypeStr[0] as '茶道' | '和菓子' | '着付け'].valName as 'tea' | 'sweets' | 'kimono';
      const level = planLevel[planLevelStr[0] as 'トライアル' | '初級' | '中級' | '上級' | '月額' | '経験者'].level;
      state.paid[type][level] = true;
    });

    const error = {
      plan: { title: 'プランが見つかりませんでした' },
      paidNow: false,
      status: 'disable',
    };

    const planSearch = (text: planText) => {
      const typeMatch = text.match(/茶道|和菓子|着付け/gi);
      const levelMatch = text.match(/トライアル|初級|中級|上級|月額|経験者/gi);
      if (typeof planList === 'string' || !typeMatch || !levelMatch) {
        return error;
      }

      const type = planType[typeMatch[0] as '茶道' | '和菓子' | '着付け'].valName as 'tea' | 'sweets' | 'kimono';
      const level = planLevel[levelMatch[0] as 'トライアル' | '初級' | '中級' | '上級' | '月額' | '経験者'].level;

      const paidNow = state.paid[type][level];
      // 初期値は課金可能
      let status = 'enable';

      if (level === 0 && state.paidHistory !== 'beginner') {
        // 既課金者 トライアルを disable
        status = 'disable';
      }

      if (paidNow) {
        // 契約中プラン disable
        switch (type) {
          case 'tea':
            status = 'disable_tea';
            break;
          case 'sweets':
            status = 'disable_sweets';

            break;
          case 'kimono':
            status = 'disable_kimono';
            break;
        }
      }
      // 既課金中で disable でも契約プランを後から色付けしてるので無料プラン課金後に有料プラン課金するときちんと色がつく、順番重要
      const plan = planList.filter((plan) => {
        if (plan.title?.match(text)) {
          return true;
        }
        return false;
      });

      return {
        plan: plan[0],
        paidNow,
        status,
      };
    };

    const getPlanFromAccessCode = async () => {
      const res = await getAccessCode({ accessCode: state.accessCode });
      if (typeof res === 'string') {
        notification.error({ title: 'エラー', message: 'エラーが発生しました' });
        return;
      } else if (res === null) {
        notification.error({ title: 'エラー', message: 'アクセスコードが見つかりませんでした' });
        return;
      }
      const matchPlan = planList.filter((plan) => {
        return plan.id === Number(res.stripeId);
      })[0];
      state.planDataFromAccessCode = {
        plan: matchPlan,
        paidNow: false,
        status: 'enable',
        accessCode: state.accessCode,
      };
    };

    return {
      state,
      planSearch,
      getPlanFromAccessCode,
      BUTTON_COLOR,
    };
  },
});
