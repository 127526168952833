
import { defineComponent, reactive, shallowRef } from 'vue';
import { useRoute } from 'vue-router';

import { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import { VideoPlayer } from '@videojs-player/vue';
import 'video.js/dist/video-js.css';

import { getVideoData, presignedUrlForGet } from '@/api/video';
import { publicBucketPath } from '@/utils/publicBucketPath';
import { VideoData } from '@/../../@types/api';

// element-plus
import { Back } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'View',
  components: {
    VideoPlayer,
    Back,
  },
  async setup() {
    const route = useRoute();
    const videoViewSrc = typeof route.params.videoviewsrc === 'string' ? route.params.videoviewsrc : '';
    const videoData = await getVideoData({ videoViewSrc });

    const state = reactive<{
      videoData: VideoData;
      videoPresignedUrl: string;
      imgPath: string;
      mode: string;
    }>({
      videoData,
      videoPresignedUrl: '',
      imgPath: '',
      mode: videoData === null ? 'notfound' : videoData.release ? 'viewer' : 'norelease',
    });
    if (state.mode !== 'viewer') {
      return { state };
    }

    try {
      state.videoPresignedUrl = await presignedUrlForGet({
        fileName: String(videoData.videoS3Path),
        bucket: 'private',
      });
    } catch {
      state.mode = 'notAuthorized';
    }
    state.imgPath = publicBucketPath(String(videoData.imgSrc));
    const player = shallowRef<VideoJsPlayer>();
    const videoOptions: VideoJsPlayerOptions = {
      autoplay: true,
      controls: true,
      playsinline: true,
      src: state.videoPresignedUrl,
      poster: state.imgPath,
      playbackRates: [0.7, 1.0, 1.5, 2.0],
    };

    const historyBack = () => {
      history.back();
    };

    return {
      state,
      player,
      videoOptions,
      historyBack,
    };
  },
});
