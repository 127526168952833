
import { defineComponent, reactive } from 'vue';
import * as notification from '@/components/_lib/notification';
import { getCourseList, upsertCourse, destroyCourse } from '@/api/course';
import { Course } from '@/../../@types/api';

export default defineComponent({
  name: 'ManagementCourse',
  async setup(props, context) {
    const state = reactive({
      createForm: {
        id: -1,
        title: '',
        description: '',
      },
      courseList: await getCourseList(),
    });

    const refresh = async () => {
      state.courseList = await getCourseList();
    };

    const formCrear = async () => {
      state.createForm.id = -1;
      state.createForm.title = '';
      state.createForm.description = '';
    };

    const upsertSubmit = async () => {
      try {
        const course = await upsertCourse(state.createForm);
        if (!course) {
          throw new Error('upsert faild');
        }
        notification.success({ title: 'success', message: '作成しました' });
        await formCrear();
      } catch {
        notification.error({ title: 'error', message: '失敗しました' });
      }
      context.emit('refresh');
    };

    const edit = async (course: Course) => {
      state.createForm.id = Number(course.id);
      state.createForm.title = String(course.title);
      state.createForm.description = String(course.description);
    };

    const destroy = async (id: number) => {
      // TODO: VideoData側から参照されてる場合は削除できないようにする
      try {
        const course = await destroyCourse(id);
        if (!course) {
          throw new Error('delete faild');
        }
        notification.success({ title: 'success', message: '削除しました' });
      } catch {
        notification.error({ title: 'error', message: '失敗しました' });
      }
      context.emit('refresh');
    };

    return {
      state,
      refresh,
      upsertSubmit,
      formCrear,
      edit,
      destroy,
    };
  },
});
