import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_Thumbnail = _resolveComponent("Thumbnail")!

  return (_ctx.state.pageMode === _ctx.CONSTANTS.PAGE_MODE.INPUT)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: _normalizeClass(_ctx.$style.input_and_search_btn)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.query_input_warper)
        }, [
          _createVNode(_component_el_autocomplete, {
            class: _normalizeClass([_ctx.$style.query_input, "inline-input w-50"]),
            modelValue: _ctx.state.autoCompleteValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.autoCompleteValue) = $event)),
            "fetch-suggestions": _ctx.fetchSuggestions,
            clearable: "",
            onSelect: _ctx.moveToVideo
          }, null, 8, ["class", "modelValue", "fetch-suggestions", "onSelect"])
        ], 2),
        _createElementVNode("div", null, [
          _createVNode(_component_el_button, {
            class: _normalizeClass(_ctx.$style.search_btn),
            round: "",
            onClick: _ctx.searchVideo
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_SearchIcon)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class", "onClick"])
        ])
      ], 2))
    : (_openBlock(), _createElementBlock("section", {
        key: 1,
        class: _normalizeClass(_ctx.$style.video_search_result)
      }, [
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.backToInput && _ctx.backToInput(...args))),
          class: _normalizeClass(_ctx.$style.search_word_preview)
        }, [
          _createElementVNode("input", {
            value: _ctx.state.autoCompleteValue,
            readonly: ""
          }, null, 8, _hoisted_1),
          _createVNode(_component_el_button, {
            class: _normalizeClass(_ctx.$style.search_btn),
            round: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_SearchIcon)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"])
        ], 2),
        (_ctx.state.activeVideoDataList.length)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.video_thumbnails)
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.activeVideoDataList, (videoData) => {
                return (_openBlock(), _createBlock(_component_Thumbnail, {
                  key: videoData.imgSrc || '',
                  "video-data": videoData
                }, null, 8, ["video-data"]))
              }), 128))
            ], 2))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.$style.video_thumbnails)
            }, "『" + _toDisplayString(_ctx.state.autoCompleteValue) + "』の検索結果はありませんでした。", 3))
      ], 2))
}