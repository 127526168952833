'use strict';

// 利用規約埋め込み
export const AGREEMENT_URL = process.env.VUE_APP_AGREEMENT_URL;
export const VIDEO_GRAPHQL_URL = '/api/video/graphql';
export const VIDEO_S3_PRESIGNED_FOR_PUT_URL = '/api/video/presignedurl/putting';
export const VIDEO_S3_PRESIGNED_FOR_GET_URL = '/api/video/presignedurl/getting';
export const VIDEO_S3_DELETE_URL = '/api/video/delete';
export const VIDEO_S3_USED_FILE_SIZE_URL = '/api/video/usedfilesize';

export const SITE_TITLE = process.env.VUE_APP_SITE_TITLE || '和楽美Online';

export const STRIPE_GRAPHQL_URL = '/api/stripe/graphql';
export const COURSE_GRAPHQL_URL = '/api/course/graphql';
export const STRIPE_TO_VIDEO_GRAPHQL_URL = '/api/stripetovideo/graphql';
export const USER_GRAPHQL_URL = '/api/user/graphql';
export const USER_ACCOUNT_GRAPHQL_URL = '/api/user/account/graphql';
export const RECEIPT_GRAPHQL_URL = '/api/receipt/graphql';
export const ACCESS_CODE_GRAPHQL_URL = '/api/accessCode/graphql';

export const PUBLIC_BUCKET_PATH = process.env.VUE_APP_PUBLIC_BUCKET_PATH;

export const BUTTON_COLOR = '#6E1419';

// 参考 https://stripe.com/docs/customer-management/activate-no-code-customer-portal
export const STRIPE_CUSTOMER_PORTAL_URL = process.env.VUE_APP_STRIPE_CUSTOMER_PORTAL_URL;
export const PLAN_PERIOD = {
  free_month: { display: '一ヶ月無料' },
  free_unlimited: { display: '永久無料' },
  onetime: { display: '一回限り' },
  day: { display: '一日' },
  week: { display: '一週間' },
  month: { display: '一ヶ月' },
  year: { display: '一年' },
};

export const role = {
  admin: { display: '管理者' },
  customer: { display: 'ユーザ' },
} as const;

// user role
export const USER_ROLE = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
} as const;

export const ENDPOINT = process.env.VUE_APP_ENDPOINT;
export const DOMAIN = process.env.VUE_APP_DOMAIN;

export const NO_LOGIN_ALLOW_URL_LIST = [
  '/',
  '/user/create',
  '/user/auth/error/.+',
  '/user/auth/success',
  '/user/emailedit/success',
  '/user/emailedit/error/.+',
  '/user/paswordreset',
  '/user/passwordreset/error/.+',
  '/user/passwordreset/input/.+',
  '/law',
  '/404',
  '/403',
];

export const ONLY_ADMIN_URL_LIST = ['/video/management'];
