
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'UserAuthSuccess',
  setup() {
    const router = useRouter();
    setTimeout(() => {
      router.push('/');
    }, 3000);
  },
});
