
import { defineComponent, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { Stripe } from '@/../../@types/api';
import { getStripe } from '@/api/stripe';

export default defineComponent({
  name: 'StripeSuccess',
  async setup() {
    const route = useRoute();
    const stripe: Stripe = await getStripe({ id: Number(route.params.planid) });
    const state = reactive({
      stripe,
      action: `/api/stripe/freeplancheckout/${Number(route.params.planid)}${
        route.query.accessCode ? `/?accessCode=${route.query.accessCode}` : ''
      }`,
    });
    const submit = () => {
      window.location.href = state.action;
    };
    return { state, submit };
  },
});
