import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.$style.thumbnailTop,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSelect && _ctx.handleSelect(...args)))
  }, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 10,
          id: _ctx.$style.imgArea
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              id: _ctx.$style.img,
              src: _ctx.imgSrc
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["id"]),
        _createVNode(_component_el_col, {
          span: 14,
          id: _ctx.$style.description
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.videoData.value), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.videoData.description), 1)
          ]),
          _: 1
        }, 8, ["id"])
      ]),
      _: 1
    })
  ], 8, _hoisted_1))
}