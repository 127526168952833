
import { defineComponent, reactive } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'UserAuthError',
  async setup() {
    const route = useRoute();
    const errorCode = route.params.errorcode as 'authdataerror' | 'cantfind' | 'timeout' | 'other';
    const state = reactive({
      errorCode,
      errorList: {
        authdataerror: { code: 0, message: '認証情報に誤りがございます。' },
        cantfind: { code: 1, message: '認証情報が見つかりませんでした。' },
        timeout: {
          code: 2,
          message: '認証時間を超過しております。お手数ですが再度アカウント登録手続きをお願いいたします。',
        },
        other: { code: 3, message: '何らかのエラーが発生しました。' },
      },
    });
    return { state };
  },
});
