
import { defineComponent, reactive } from 'vue';
import { Loading } from '@element-plus/icons-vue';
import { BUTTON_COLOR } from '@/constants/constant';
import { getUserPasswordResetRequest } from '@/api/user';
import * as notification from '@/components/_lib/notification';

export default defineComponent({
  name: 'PasswordReset',
  components: {
    Loading,
  },
  async setup() {
    const state = reactive({
      email: '',
      submitted: false,
      sending: false,
    });

    const submit = async () => {
      state.sending = true;
      const user = await getUserPasswordResetRequest({ email: state.email });
      state.sending = false;
      if (typeof user === 'string' || user === null) {
        notification.error({ title: 'エラー', message: '失敗しました' });
        return;
      }
      state.submitted = true;
    };
    return {
      state,
      submit,
      BUTTON_COLOR,
    };
  },
});
