'use strict';

import { request, gql } from 'graphql-request';
import axios from 'axios';
import { Receipt } from '@/../../@types/api';
import { RECEIPT_GRAPHQL_URL } from '@/constants/constant';

export const getValidReceiptList = async (args: { userId: number }): Promise<Receipt[]> => {
  const query = gql`
    query {
      getValidReceiptList(userId: ${args.userId}) {
      receiptList {
        id
        userId
        stripeSubscriptionId
        planId
        deadline
      }
    }
  }
  `;
  const res = async () => {
    try {
      return (await request(RECEIPT_GRAPHQL_URL, query)).getValidReceiptList.receiptList;
    } catch (e) {
      return `${e}`;
    }
  };
  return res();
};

export const getReceiptList = async (args: { userId: number }): Promise<Receipt[]> => {
  const query = gql`
    query {
      getReceiptList(userId: ${args.userId}) {
      receiptList {
        id
        userId
        stripeSubscriptionId
        planId
        deadline
        created
      }
    }
  }
  `;
  const res = async () => {
    try {
      return (await request(RECEIPT_GRAPHQL_URL, query)).getReceiptList.receiptList;
    } catch (e) {
      return `${e}`;
    }
  };
  return res();
};

export const getPaidStatus = async (userId: number): Promise<'beginner' | 'paidButNotYear' | 'subscriber'> => {
  const res = await axios.get(`/api/receipt/paidstatus/${userId}`);
  return res.data;
};
