
import { defineComponent, reactive, inject } from 'vue';
import axios from 'axios';
import { VueCookies } from 'vue-cookies';
import * as notification from '@/components/_lib/notification';
import { BUTTON_COLOR, DOMAIN } from '@/constants/constant';

export default defineComponent({
  name: 'Home',
  async setup() {
    const $cookies = inject<VueCookies>('$cookies');
    const state = reactive({
      loginData: {
        email: '',
        pass: '',
      },
      session: $cookies?.get('session'),
    });
    const login = async () => {
      if (state.loginData.email === '') {
        notification.error({ title: 'エラー', message: 'メールアドレスを入力して下さい' });
        return;
      }
      if (state.loginData.pass.trim() === '') {
        notification.error({ title: 'エラー', message: 'パスワードを入力して下さい' });
        return;
      }

      const res = await (async () => {
        try {
          return await axios.post(
            `/login`,
            {
              email: state.loginData.email,
              pass: state.loginData.pass,
            },
            {
              withCredentials: true,
            }
          );
        } catch (e) {
          return `${e}`;
        }
      })();

      if (typeof res === 'string') {
        notification.error({ title: 'エラー', message: 'ログインできませんでした' });
        return;
      }
      notification.info({ title: '', message: 'ログインしました' });
      location.href = '/video/search';
    };

    const logout = async () => {
      $cookies?.remove('session', undefined, DOMAIN);
      location.reload();
    };

    return {
      state,
      login,
      logout,
      BUTTON_COLOR,
    };
  },
});
