
import { defineComponent, reactive, computed } from 'vue';
import { User, AccessCode, Receipt } from '@/../../@types/api';
import { getUserList, updateUserRole, sendEmailWithAccessCode } from '@/api/user';
import { getReceiptList } from '@/api/receipt';
import { getStripeListUsingAndUnUsing } from '@/api/stripe';
import { getAccessCodeList } from '@/api/accessCode';
import { BUTTON_COLOR, USER_ROLE, role, SITE_TITLE } from '@/constants/constant';
import * as notification from '@/components/_lib/notification';

const ACCESS_CODE_REPLACE_TEXT = '#WAC#';

export default defineComponent({
  name: 'ManagementUser',
  async setup(props, context) {
    const state = reactive({
      userList: await getUserList(),
      selectedUserFlagList: [] as boolean[],
      accessCodeList: [] as AccessCode[],
      selectedAccessCode: '',
      paymentHistoryVisible: false,
      accessCodeConfigVisible: false,
      sortingTableVisible: false,
      paymentHistories: [] as { created: string; deadline: string }[],
      stripePlanList: await getStripeListUsingAndUnUsing(),
      accessCodeMailTitle: `${SITE_TITLE} [配布] アクセスコードのご案内`,
      accessCodeMailBody: `${SITE_TITLE} をご利用いただきありがとうございます。\nアクセスコードを申し込みページに入力していただくことでアクセスコードをご存知の方限定のプランを確認いただけます。\nアクセスコード: ${ACCESS_CODE_REPLACE_TEXT}\n※アクセスコードの枚数には限りがございますのでお早めにご利用をお願いいたします。`,
      selectedPlanId: null as null | number,
      countOfCheckedUsers: 0,
    });

    state.selectedUserFlagList = [false, ...state.userList].map(() => false);

    const selectedCount = computed(() => state.selectedUserFlagList.filter((flag) => flag).length);

    const isEnoughAccessCodeBySelectedCount = computed(
      () => selectedCount.value <= (getAccessCodeObjByAccessCode(state.selectedAccessCode)?.remainingNumber || 0)
    );

    const getAccessCodeObjByAccessCode = (accessCode: string) => {
      return state.accessCodeList.find((ac) => ac.accessCode === accessCode);
    };

    const changeRole = async (user: User) => {
      await updateUserRole({
        id: Number(user.id),
        role: String(user.role) === USER_ROLE.CUSTOMER ? USER_ROLE.ADMIN : USER_ROLE.CUSTOMER,
      });
      context.emit('refresh');
    };

    const showPaymentHistory = async (user: User) => {
      state.paymentHistories = (await getReceiptList({ userId: Number(user.id) })).map((receipt) => {
        const plan = getPlanFromPlanId(Number(receipt.planId));
        return {
          created: dateForString(receipt.created),
          title: plan.title,
          amount: Number(plan.amount).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' }),
          deadline: dateForString(receipt.deadline),
        };
      });
      state.paymentHistoryVisible = true;
    };

    const fetchAccessCodesAndShowConfig = async () => {
      state.accessCodeList = await getAccessCodeList();
      state.accessCodeConfigVisible = true;
    };

    const dateForString = (date: Date) => {
      const d = new Date(date);
      return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
    };

    const getPlanFromPlanId = (planId: number) => {
      const matchPlan = state.stripePlanList.filter((plan) => {
        return plan.id === planId;
      })[0];
      return matchPlan;
    };

    const refresh = async () => {
      state.userList = await getUserList();
    };

    const roleToJa = (roleKey: string) => {
      return role[roleKey as keyof typeof role].display;
    };

    const callApiOfSendEmailWithAccessCode = async () => {
      state.accessCodeConfigVisible = false;
      const userIdList = state.selectedUserFlagList
        .map((flag, flagIndex) => (flag ? state.userList.find((user: User) => user.id === flagIndex)?.id || 0 : null))
        .filter((userId) => userId);

      try {
        await sendEmailWithAccessCode({
          title: state.accessCodeMailTitle,
          bodyWithAccessCode: state.accessCodeMailBody
            .replace(new RegExp(ACCESS_CODE_REPLACE_TEXT, 'g'), state.selectedAccessCode)
            .replace(/\n/g, '<br>'),
          userIdList,
        });
        notification.success({ title: 'メール送信', message: '開始されました。' });
      } catch (error) {
        notification.error({ title: 'error', message: '通信エラーが発生しました。' });
      }

      // 初期化
      state.selectedUserFlagList = [false, ...state.userList].map(() => false);
      state.selectedAccessCode = '';
    };

    const selectUsersByPlan = async () => {
      state.countOfCheckedUsers = 0;

      for (const user of state.userList) {
        state.selectedUserFlagList[user.id || 0] = (await getReceiptList({ userId: Number(user.id) })).some(
          (receipt: Receipt) => receipt.planId === state.selectedPlanId
        );
        ++state.countOfCheckedUsers;
      }

      const selectedUserFlagCount = state.selectedUserFlagList.filter((flag) => flag).length;
      if (selectedUserFlagCount) {
        notification.success({ title: '自動選択完了', message: `${selectedUserFlagCount}人にチェックを入れました。` });
      } else {
        notification.error({
          title: '購入者0人',
          message: '該当のプランを購入したユーザはいません。必要に応じ管理画面をリロードし再度お試しください。',
        });
      }
    };

    return {
      state,
      selectedCount,
      isEnoughAccessCodeBySelectedCount,
      callApiOfSendEmailWithAccessCode,
      refresh,
      changeRole,
      showPaymentHistory,
      fetchAccessCodesAndShowConfig,
      roleToJa,
      role,
      selectUsersByPlan,
      BUTTON_COLOR,
      ACCESS_CODE_REPLACE_TEXT,
    };
  },
});
