
import { defineComponent, reactive } from 'vue';
import VideoDataForm from '@/components/modules/VideoDataForm.vue';
import { presignedUrlForPut, uploadPresignedUrl, upsertVideoData, getVideoData, destroyVideoData } from '@/api/video';
import { getStripeList } from '@/api/stripe';
import { getCourseList } from '@/api/course';
import { upsertStripeToVideo } from '@/api/stripeToVideo';
import { BUTTON_COLOR } from '@/constants/constant';

const enptyFile = new File([], 'empty');

export default defineComponent({
  name: 'Upload',
  components: {
    VideoDataForm,
  },
  async setup(props, context) {
    const state = reactive({
      newFile: {
        value: '',
        description: '',
        videoViewSrc: '',
        videoFile: enptyFile,
        imgFile: enptyFile,
        courseId: -1,
        release: true,
      },
      stripePlanList: await getStripeList(),
      selectPlan: [false],
      courseList: await getCourseList(),

      // v-show で入力画面と送信処理中画面の切り替え
      inputDisable: false,
      // 送信処理中この配列に処理状況の文字列が push されてくる
      submitStatus: ['送信処理中'],
      // 送信後、前回の入力内容をクリアして次の動画の投稿に移る際
      // v-if で input type=form を消して作り直して内容をクリア
      refresh: true,
      videoPreview: '',
      imgPreview: '',
    });

    const refresh = async () => {
      state.stripePlanList = await getStripeList();
      state.courseList = await getCourseList();
    };

    const formChange = (args: {
      videoDataRaw: { value: string; description: string; videoViewSrc: string; courseId: number; release: boolean };
      selectPlan: Array<boolean>;
    }) => {
      state.newFile.value = args.videoDataRaw.value;
      state.newFile.description = args.videoDataRaw.description;
      state.newFile.videoViewSrc = args.videoDataRaw.videoViewSrc;
      state.newFile.courseId = args.videoDataRaw.courseId;
      state.newFile.release = args.videoDataRaw.release;
      state.selectPlan = args.selectPlan;
    };

    const videoOnChange = (input: Event) => {
      const { target } = input;
      if (!(target instanceof HTMLInputElement)) {
        return;
      }
      fileOnChange(target, 'video');
    };
    const imgOnChange = (input: Event) => {
      const { target } = input;
      if (!(target instanceof HTMLInputElement)) {
        return;
      }
      fileOnChange(target, 'img');
    };
    const fileOnChange = async (target: HTMLInputElement, type: 'video' | 'img') => {
      if (!target.files) return;
      const file = target.files[0];
      const reader = new FileReader();
      if (type === 'video') {
        state.newFile.videoFile = file;
        reader.onload = () => {
          state.videoPreview = String(reader.result);
        };
        reader.readAsDataURL(file);
      } else if (type === 'img') {
        state.newFile.imgFile = file;
        reader.onload = () => {
          state.imgPreview = String(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

    const clearStatus = async () => {
      state.newFile = {
        value: '',
        description: '',
        videoViewSrc: '',
        videoFile: enptyFile,
        imgFile: enptyFile,
        courseId: -1,
        release: true,
      };
      state.selectPlan = [false];
      state.submitStatus = ['送信処理中'];
      state.inputDisable = false;
      state.videoPreview = '';
      state.imgPreview = '';
      await (() => {
        state.refresh = false;
      })();
      state.refresh = true;
    };
    const fixStatus = () => {
      state.submitStatus = ['送信処理中'];
      state.inputDisable = false;
    };

    const upload = async () => {
      state.inputDisable = true;
      // TODO: エラーメッセージを出す
      if (
        state.newFile.value === '' ||
        state.newFile.description === '' ||
        state.newFile.videoViewSrc === '' ||
        state.newFile.videoFile.name === 'empty' ||
        state.newFile.imgFile.name === 'empty' ||
        state.newFile.courseId === -1
      ) {
        state.submitStatus.push('入力に不備があります');
        return;
      }

      // videoViewSrc バリテーション
      if (await getVideoData({ videoViewSrc: state.newFile.videoViewSrc })) {
        state.submitStatus.push('既にその公開リンクの動画が存在しています。リンクを変えて投稿し直してください。');
        return;
      }

      // s3 アップロード用 URL 取得
      const s3VideoPutRes = await (async () => {
        try {
          return await presignedUrlForPut({
            fileName: state.newFile.videoFile.name,
            bucket: 'private',
          });
        } catch (e) {
          return `${e}`;
        }
      })();
      if (typeof s3VideoPutRes === 'string' || !s3VideoPutRes.s3_path.match(/^http/)) {
        state.submitStatus.push('ビデオアップロードパスが取得できません');
        return;
      }

      const s3VideoPutPath = s3VideoPutRes.s3_path;
      const videoS3Name = s3VideoPutRes.file_name;
      const s3ImgPutRes = await (async () => {
        try {
          return await presignedUrlForPut({
            fileName: state.newFile.imgFile.name,
            bucket: 'public',
          });
        } catch (e) {
          return `${e}`;
        }
      })();

      if (typeof s3ImgPutRes === 'string' || !s3ImgPutRes.s3_path.match(/^http/)) {
        state.submitStatus.push('画像アップロードパスが取得できません');
        return;
      }
      const s3ImgPutPath = s3ImgPutRes.s3_path;
      const imgS3Name = s3ImgPutRes.file_name;

      // VideoData 作成
      const newVideoData = await (async () => {
        try {
          return await upsertVideoData({
            imgStr: imgS3Name,
            videoViewSrc: state.newFile.videoViewSrc,
            videoS3Path: videoS3Name,
            value: state.newFile.value,
            description: state.newFile.description,
            courseId: state.newFile.courseId,
            release: state.newFile.release,
          });
        } catch (e) {
          return `${e}`;
        }
      })();
      if (!newVideoData || typeof newVideoData === 'string') {
        state.submitStatus.push('動画情報の保存に失敗しました');
        return;
      }

      // s3 upload
      state.submitStatus.push('ビデオアップロードの用意が出来ました');
      const videoRes = await uploadPresignedUrl({ url: s3VideoPutPath, file: state.newFile.videoFile });
      if (videoRes.status === 200) {
        state.submitStatus.push('動画アップロード完了');
      } else {
        state.submitStatus.push('動画のアップロードに失敗しました');
        await videoDataDestroy(Number(newVideoData.id));
        return;
      }
      const imgRes = await uploadPresignedUrl({ url: s3ImgPutPath, file: state.newFile.imgFile });
      if (imgRes.status === 200) {
        state.submitStatus.push('画像アップロード完了');
      } else {
        state.submitStatus.push('画像のアップロードに失敗しました');
        await videoDataDestroy(Number(newVideoData.id));
        return;
      }

      // 課金プラン設定
      const selectedPlanList = state.stripePlanList.filter((plan) => {
        return !!state.selectPlan[Number(plan.id)];
      });
      for (const plan of selectedPlanList) {
        try {
          await upsertStripeToVideo({ stripeId: Number(plan.id), videoId: Number(newVideoData.id) });
          state.submitStatus.push(`課金プランを設定しました: ${plan.title}`);
        } catch {
          state.submitStatus.push('課金プランの設定に失敗しました');
          await videoDataDestroy(Number(newVideoData.id));
          return;
        }
      }

      state.submitStatus.push('全ての操作が完了しました');
      context.emit('refresh');
    };

    // videoData の削除
    const videoDataDestroy = async (videoId: number) => {
      const deletedVideoData = await (async () => {
        try {
          return await destroyVideoData(videoId);
        } catch (e) {
          return `${e}`;
        }
      })();
      if (typeof deletedVideoData === 'string') {
        state.submitStatus.push('情報の削除に失敗しました。閲覧不可能な動画情報が残ってしまった可能性があります');
        return;
      }
      state.submitStatus.push('動画情報をリセットしました');
    };

    return {
      state,
      BUTTON_COLOR,
      refresh,
      upload,
      videoOnChange,
      clearStatus,
      fixStatus,
      imgOnChange,
      formChange,
    };
  },
});
