
// vue
import { defineComponent, reactive, watch, toRef } from 'vue';
import { useRouter } from 'vue-router';

// type
import { VideoData } from '@/../../@types/api';

// api
import { getVideoDataListForCustomer } from '@/api/video';

// element-plus
import { Search as SearchIcon } from '@element-plus/icons-vue';

// components
import Thumbnail from '@/components/Thumbnail.vue';

const CONSTANTS = {
  PAGE_MODE: {
    INPUT: 'input',
    RESULT: 'result',
  },
} as const;

type PageMode = typeof CONSTANTS.PAGE_MODE.INPUT | typeof CONSTANTS.PAGE_MODE.RESULT;

export default defineComponent({
  name: 'SearchSP',
  components: {
    Thumbnail,
    SearchIcon,
  },
  async setup() {
    const router = useRouter();

    const state = reactive({
      pageMode: 'input' as PageMode,
      videoDataList: [] as VideoData[],
      activeVideoDataList: [] as VideoData[],
      autoCompleteValue: '',
    });

    const statePageModeRef = toRef(state, 'pageMode');

    /**
     * 検索ワード入力時と検索結果表示時で背景色が異なるので変更する
     */
    const reflectBackgroundColorFromPageMode = (pageMode: PageMode) => {
      // 親コンポートの色を変える必要があるので仕方なく document を使う
      document.body.style.backgroundColor = pageMode === CONSTANTS.PAGE_MODE.RESULT ? '#e6e5a3' : '#e5d9b6';
    };
    reflectBackgroundColorFromPageMode(state.pageMode); // ページロード時に色を変える
    watch(statePageModeRef, reflectBackgroundColorFromPageMode); // モード切り替え時に色を変える

    // お客様の動画情報を全件取得し state へ反映
    const videoDataList = await getVideoDataListForCustomer();
    state.videoDataList = videoDataList;

    const fetchSuggestions = (queryString: string, cb: (data: VideoData[]) => void) => {
      if (!(queryString || '').trim()) {
        // 検索ワードが無かったら全件を返す
        cb(state.videoDataList); // 渡された配列に入ってるものをサジェスト表示
        return;
      }
      // 渡された配列に入ってるものをサジェスト表示
      cb(
        state.videoDataList.filter((video: VideoData) =>
          // それぞれ小文字にしたビデオ名と検索ワードが一致する部分があれば true を返し絞り込む
          video.value?.toLowerCase().match(queryString.toLowerCase())
        )
      );
    };

    const moveToVideo = (item: VideoData) => {
      router.push(`/video/view/${item.videoViewSrc}`);
      return;
    };

    const searchVideo = () => {
      state.pageMode = CONSTANTS.PAGE_MODE.RESULT;

      state.activeVideoDataList = state.videoDataList.filter((videoData) => {
        return !!videoData.value?.match(state.autoCompleteValue);
      });
    };

    const backToInput = () => {
      state.pageMode = CONSTANTS.PAGE_MODE.INPUT;
    };

    return {
      CONSTANTS,
      state,
      fetchSuggestions,
      moveToVideo,
      searchVideo,
      backToInput,
    };
  },
});
