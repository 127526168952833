'use strict';

import sortBy from 'lodash.sortby';
import { request, gql } from 'graphql-request';
import { Course } from '@/../../@types/api';
import { COURSE_GRAPHQL_URL } from '@/constants/constant';

export const getCourseList = async (): Promise<Course[]> => {
  const query = gql`
    query {
      getCourseList {
        courseList {
          id
          title
          description
          using
        }
      }
    }
  `;
  const res = await request(COURSE_GRAPHQL_URL, query);
  return sortBy(res.getCourseList.courseList, ['title'], ['asc']);
};

export const upsertCourse = async (args: { id?: number; title: string; description: string }): Promise<Course> => {
  const idQuery = args.id !== -1 ? `id: ${args.id},` : '';
  const query = gql`
    mutation {
      upsertCourse(
        ${idQuery}
        title: "${args.title}",
        description: "${args.description}",
        ) {
        ...courseFields
      }
    }
    fragment courseFields on Course {
      title
      description
      using
    }
  `;
  const res = async () => {
    try {
      return (await request(COURSE_GRAPHQL_URL, query)).upsertCourse;
    } catch (e) {
      throw new Error(`${e}`);
    }
  };
  return res();
};

export const destroyCourse = async (id: number): Promise<Course> => {
  const query = gql`
    mutation {
      upsertCourse(
        id: ${id},
        using: false
        ) {
        ...courseFields
      }
    }
    fragment courseFields on Course {
      using
    }
  `;
  const res = async () => {
    try {
      return (await request(COURSE_GRAPHQL_URL, query)).upsertCourse;
    } catch (e) {
      throw new Error(`${e}`);
    }
  };

  return res();
};
