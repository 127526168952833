
import { defineComponent, reactive, toRef } from 'vue';
import ManagementStripe from '@/components/management/Stripe.vue';
import ManagementVideoUpload from '@/components/management/Upload.vue';
import ManagementCourse from '@/components/management/Course.vue';
import ManagementVideoEdit from '@/components/management/VideoEdit.vue';
import ManagementAccessCode from '@/components/management/AccessCode.vue';
import ManagementUser from '@/components/management/User.vue';

export default defineComponent({
  name: 'Management',
  components: {
    ManagementStripe,
    ManagementVideoUpload,
    ManagementCourse,
    ManagementVideoEdit,
    ManagementAccessCode,
    ManagementUser,
  },
  async setup() {
    const state = reactive({
      // reactive で各コンポーネントを初期化
      managementCourse: {} as InstanceType<typeof ManagementCourse>,
      managementStripe: {} as InstanceType<typeof ManagementStripe>,
      managementVideoUpload: {} as InstanceType<typeof ManagementVideoUpload>,
      managementVideoEdit: {} as InstanceType<typeof ManagementVideoEdit>,
      managementAccessCode: {} as InstanceType<typeof ManagementAccessCode>,
      managementUser: {} as InstanceType<typeof ManagementUser>,
      display: 'planAndCourse',
    });
    // 各コンポーネントの ref を作成
    const ManagementCourseRef = toRef(state, 'managementCourse');
    const ManagementStripeRef = toRef(state, 'managementStripe');
    const ManagementVideoUploadRef = toRef(state, 'managementVideoUpload');
    const ManagementVideoEditRef = toRef(state, 'managementVideoEdit');
    const ManagementAccessCodeRef = toRef(state, 'managementAccessCode');
    const ManagementUserRef = toRef(state, 'managementUser');
    // 各コンポーネントで編集があった際 refresh が emit される
    // 受け取ったら各コンポーネントの refresh() 関数を呼び最新の状態にする
    const refresh = async () => {
      if (state.display === 'planAndCourse') {
        ManagementCourseRef.value?.refresh();
        ManagementStripeRef.value?.refresh();
        ManagementAccessCodeRef.value?.refresh();
        ManagementUserRef.value?.refresh();
      } else {
        ManagementVideoUploadRef.value?.refresh();
        ManagementVideoEditRef.value?.refresh();
      }
    };

    const selectChange = (name: string) => {
      state.display = name;
    };

    return {
      refresh,
      state,
      ManagementCourseRef,
      ManagementStripeRef,
      ManagementVideoUploadRef,
      ManagementVideoEditRef,
      ManagementAccessCodeRef,
      ManagementUserRef,
      selectChange,
    };
  },
});
