
import { defineComponent, reactive, inject } from 'vue';
import { VueCookies } from 'vue-cookies';
import { SITE_TITLE } from '@/constants/constant';
import { Menu } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'App',
  components: {
    Menu,
  },
  setup() {
    const $cookies = inject<VueCookies>('$cookies');
    const state = reactive({
      siteTitle: SITE_TITLE,
      menuOpen: false,
      ifLogin: false,
    });

    state.ifLogin = $cookies?.get('session') ? true : false;

    const menuIconClick = () => {
      state.menuOpen = !state.menuOpen;
    };

    const menuClick = () => {
      state.menuOpen = false;
    };

    return {
      state,
      menuIconClick,
      menuClick,
    };
  },
});
