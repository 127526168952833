
import { defineComponent, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getUserPasswordResetInput } from '@/api/user';
import * as notification from '@/components/_lib/notification';
import { BUTTON_COLOR } from '@/constants/constant';

export default defineComponent({
  name: 'PasswordResetInput',
  async setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      pass: '',
      confirmPass: '',
      submitted: false,
    });

    const submit = async () => {
      if (state.pass != state.confirmPass) {
        notification.error({ title: 'エラー', message: 'パスワードが一致しません' });
        return;
      }
      const user = await getUserPasswordResetInput({ pass: state.pass, auth: String(route.params.auth) });
      if (typeof user === 'string' || user === null) {
        notification.error({ title: 'エラー', message: '失敗しました' });
        return;
      }
      state.submitted = true;
    };
    return {
      state,
      submit,
      router,
      BUTTON_COLOR,
    };
  },
});
