
import { defineComponent, reactive } from 'vue';
import * as notification from '@/components/_lib/notification';
import { getAccessCodeList, upsertAccessCode, destroyAccessCode } from '@/api/accessCode';
import { getStripeList } from '@/api/stripe';
import { AccessCode } from '@/../../@types/api';
import { BUTTON_COLOR } from '@/constants/constant';

export default defineComponent({
  name: 'ManagementAccessCode',
  async setup(props, context) {
    const state = reactive({
      createForm: {
        id: -1,
        stripeId: -1,
        accessCode: '',
        remainingNumber: 0,
      },
      accessCodeList: await getAccessCodeList(),
      stripePlanList: await getStripeList(),
    });

    const refresh = async () => {
      state.accessCodeList = await getAccessCodeList();
      state.stripePlanList = await getStripeList();
    };

    const formCrear = async () => {
      state.createForm = {
        id: -1,
        stripeId: -1,
        accessCode: '',
        remainingNumber: 0,
      };
    };

    const upsertSubmit = async () => {
      const res = await (async () => {
        try {
          return await upsertAccessCode(state.createForm);
        } catch (e) {
          return `${e}`;
        }
      })();
      if (typeof res === 'object') {
        notification.success({ title: 'success', message: '作成しました' });
        context.emit('refresh');
        formCrear();
      } else {
        notification.error({ title: 'error', message: '失敗しました' });
      }
    };

    const edit = async (accessCode: AccessCode) => {
      state.createForm.id = Number(accessCode.id);
      state.createForm.stripeId = Number(accessCode.stripeId);
      state.createForm.accessCode = String(accessCode.accessCode);
      state.createForm.remainingNumber = Number(accessCode.remainingNumber);
    };

    const destroy = async (accessCode: AccessCode) => {
      try {
        await destroyAccessCode({ id: Number(accessCode.id) });
        notification.success({ title: 'success', message: '削除しました' });
      } catch {
        notification.error({ title: 'error', message: '失敗しました' });
      }
      context.emit('refresh');
      formCrear();
    };

    const getStripePlanName = (stripeId: number) => {
      return state.stripePlanList.filter((plan) => {
        return !!(plan.id === stripeId);
      })[0].title;
    };

    return {
      state,
      refresh,
      upsertSubmit,
      formCrear,
      edit,
      destroy,
      getStripePlanName,
      BUTTON_COLOR,
    };
  },
});
