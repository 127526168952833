'use strict';

import { request, gql } from 'graphql-request';
import sortBy from 'lodash.sortby';
import { Stripe } from '@/../../@types/api';
import { STRIPE_GRAPHQL_URL } from '@/constants/constant';

export const getStripeList = async (args?: { type?: string; target?: string }): Promise<Stripe[]> => {
  const query = gql`
    query {
      getStripeList ${args ? '(' : ''}
        ${args?.type ? `type: "${args.type}"` : ''}
        ${args?.target ? `target: "${args.target}"` : ''}
        ${args ? ')' : ''} {
        stripeList {
          id
          title
          description
          stripePriceId
          stripeProductId
          amount
          type
          target
          using
        }
      }
    }
  `;
  const res = await request(STRIPE_GRAPHQL_URL, query);
  return sortBy(res.getStripeList.stripeList, ['title'], ['asc']);
};

export const getStripeListUsingAndUnUsing = async (args?: { type?: string; target?: string }): Promise<Stripe[]> => {
  const query = gql`
    query {
      getStripeListUsingAndUnUsing ${args ? '(' : ''}
        ${args?.type ? `type: "${args.type}"` : ''}
        ${args?.target ? `target: "${args.target}"` : ''}
        ${args ? ')' : ''} {
        stripeList {
          id
          title
          description
          stripePriceId
          stripeProductId
          amount
          type
          target
          using
        }
      }
    }
  `;
  const res = await request(STRIPE_GRAPHQL_URL, query);
  return sortBy(res.getStripeListUsingAndUnUsing.stripeList, ['title'], ['asc']);
};

export const getStripeListForCustomer = async (): Promise<Stripe[] | string> => {
  const query = gql`
    query {
      getStripeListForCustomer {
        stripeList {
          id
          title
          description
          stripePriceId
          stripeProductId
          amount
          type
          target
          using
        }
      }
    }
  `;
  const res = (async () => {
    try {
      const stripeList = await request(STRIPE_GRAPHQL_URL, query);
      return sortBy(stripeList.getStripeListForCustomer.stripeList, ['title'], ['asc']);
    } catch (e) {
      return `${e}`;
    }
  })();
  return res;
};

export const getStripe = async (args: { id: number }): Promise<Stripe> => {
  const query = gql`
    query {
      getStripe( id: ${args.id}) {
          id
          title
          description
          amount
          stripePriceId
          stripeProductId
          type
          target
          using
      }
    }
  `;
  const res = (async () => {
    try {
      return (await request(STRIPE_GRAPHQL_URL, query)).getStripe;
    } catch (e) {
      return `${e}`;
    }
  })();
  return res;
};

export const upsertStripe = async (args: {
  id?: number;
  title: string;
  description: string;
  amount: number;
  stripePriceId: string | null;
  stripeProductId: string | null;
  type: string;
  target: string;
}): Promise<Stripe> => {
  const idQuery = args.id !== -1 ? `id: ${args.id},` : '';
  const query = gql`
    mutation {
      upsertStripe(
        ${idQuery}
        title: "${args.title}",
        description: "${args.description}",
        amount: ${args.amount}
        stripePriceId: "${args.stripePriceId}",
        stripeProductId: "${args.stripeProductId}"
        type: "${args.type}"
        target: "${args.target}"
        ) {
        ...stripeFields
      }
    }
    fragment stripeFields on Stripe {
      title
      description
      amount
      stripePriceId
      stripeProductId
      type
      target
      using
    }
  `;
  const res = async () => {
    try {
      return (await request(STRIPE_GRAPHQL_URL, query)).upsertStripe;
    } catch (e) {
      throw new Error(`${e}`);
    }
  };
  return res();
};

export const destroyStripe = async (args: { id: number; stripeProductId?: string }): Promise<Stripe> => {
  const query = gql`
    mutation {
      upsertStripe(
        id: ${args.id},
        ${args.stripeProductId ? `stripeProductId: "${args.stripeProductId}"` : ''} 
        using: false
        ) {
        ...stripeFields
      }
    }
    fragment stripeFields on Stripe {
      using
    }
  `;
  const res = async () => {
    try {
      return (await request(STRIPE_GRAPHQL_URL, query)).upsertStripe;
    } catch (e) {
      throw new Error(`${e}`);
    }
  };

  return res();
};
