'use strict';

import { request, gql } from 'graphql-request';
import { StripeToVideo } from '@/../../@types/api';
import { STRIPE_TO_VIDEO_GRAPHQL_URL } from '@/constants/constant';

export const getStripeToVideoList = async (args: {
  id?: number;
  videoId?: number;
  stripeId?: number;
}): Promise<StripeToVideo[]> => {
  const idQuery = `${args.id ? `id: ${args.id}` : ''}`;
  const videoIdQuery = `${args.videoId ? `videoId: ${args.videoId}` : ''}`;
  const stripeIdQuery = `${args.stripeId ? `stripeId: ${args.stripeId}` : ''}`;
  const query = gql`
    query {
      getStripeToVideoList (
        ${idQuery}
        ${videoIdQuery}
        ${stripeIdQuery}
      ){
        stripeToVideoList{
          id
          videoId
          stripeId
        }
      }
    }
  `;

  const res = await (async () => {
    try {
      return (await request(STRIPE_TO_VIDEO_GRAPHQL_URL, query)).getStripeToVideoList.stripeToVideoList;
    } catch (e) {
      throw new Error(`${e}`);
    }
  })();
  return res;
};

export const upsertStripeToVideo = async (args: { stripeId: number; videoId: number }): Promise<StripeToVideo> => {
  const query = gql`
    mutation {
      upsertStripeToVideo(
        stripeId: ${args.stripeId},
        videoId: ${args.videoId},
        ) {
        ...stripeToVideoFields
      }
    }
    fragment stripeToVideoFields on StripeToVideo {
      id
      stripeId
      videoId
    }
  `;
  const res = async () => {
    try {
      return (await request(STRIPE_TO_VIDEO_GRAPHQL_URL, query)).upsertStripeToVideo;
    } catch (e) {
      throw new Error(`${e}`);
    }
  };
  return res();
};

export const destroyStripeToVideo = async (id: number): Promise<StripeToVideo> => {
  const query = gql`
    mutation {
      upsertStripeToVideo(
        id: ${id},
        using: false
        ) {
        ...StripeToVideoFields
      }
    }
    fragment StripeToVideoFields on StripeToVideo {
      using
    }
  `;
  const res = async () => {
    try {
      return (await request(STRIPE_TO_VIDEO_GRAPHQL_URL, query)).upsertStripeToVideo;
    } catch (e) {
      throw new Error(`${e}`);
    }
  };
  return res();
};
