import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.$style.parent,
    class: _normalizeClass(_ctx.$style[_ctx.props.data.status]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.formSubmit && _ctx.formSubmit(...args)))
  }, [
    _createElementVNode("div", {
      id: _ctx.$style.disable
    }, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.props.data.plan.title), 1),
      (_ctx.props.data.status === 'enable')
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.props.data.plan.amount) + " 円/ " + _toDisplayString(_ctx.state.period), 1))
        : _createCommentVNode("", true),
      (!_ctx.props.data.paidNow && _ctx.props.data.status !== 'enable')
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, "受講できません"))
        : _createCommentVNode("", true),
      (_ctx.props.data.paidNow && _ctx.props.data.status !== 'enable')
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, "受講中"))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2)
  ], 10, _hoisted_1))
}