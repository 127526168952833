'use strict';

import { request, gql } from 'graphql-request';
import { User, MutationSendEmailWithAccessCodeArgs } from '@/../../@types/api';
import sortBy from 'lodash.sortby';

import { USER_GRAPHQL_URL, USER_ACCOUNT_GRAPHQL_URL } from '@/constants/constant';

export const getUserBySession = async (): Promise<User> => {
  const query = gql`
    query {
      getUserBySession {
        id
        name
        email
        auth
        role
      }
    }
  `;
  const res = (async () => {
    try {
      return (await request(USER_GRAPHQL_URL, query)).getUserBySession;
    } catch (e) {
      throw new Error(`${e}`);
    }
  })();
  return res;
};

export const createUser = async (args: { name: string; email: string; pass: string }): Promise<User> => {
  const query = gql`
    mutation {
      createUser(
        name: "${args.name}"
        email: "${args.email}"
        pass: "${args.pass}"
      ){
        id
        name
        email
        auth
        stripeCustomerId
        role
      }
    }
    `;
  const res = await request(USER_ACCOUNT_GRAPHQL_URL, query);
  return res.createUser;
};

export const updateUsername = async (args: { id: number; name: string }): Promise<User> => {
  const query = gql`
    mutation {
      updateUsername(
        id: ${args.id}
        name: "${args.name}"
      ){
        id
        name
        email
        auth
        role
      }
    }
    `;
  const res = (async () => {
    try {
      return (await request(USER_GRAPHQL_URL, query)).updateUsername;
    } catch (e) {
      return `${e}`;
    }
  })();
  return res;
};

export const updateUserEmail = async (args: { id: number; email: string }): Promise<User> => {
  const query = gql`
    mutation {
      updateUserEmail(
        id: ${args.id}
        email: "${args.email}"
      ){
        id
        name
        email
        auth
        role
      }
    }
    `;
  const res = (async () => {
    try {
      return (await request(USER_GRAPHQL_URL, query)).updateUsername;
    } catch (e) {
      return `${e}`;
    }
  })();
  return res;
};

export const updateUserPass = async (args: { id: number; pass: string }): Promise<User> => {
  const query = gql`
    mutation {
      updateUserPass(
        id: ${args.id}
        pass: "${args.pass}"
      ){
        id
        name
        email
        auth
        role
      }
    }
    `;
  const res = (async () => {
    try {
      return (await request(USER_GRAPHQL_URL, query)).updateUsername;
    } catch (e) {
      return `${e}`;
    }
  })();
  return res;
};

export const getUserPasswordResetRequest = async (args: { email: string }): Promise<User> => {
  const query = gql`
    query {
      getUserPasswordResetRequest(
        email: "${args.email}"
      ) {
        id
        name
        email
        auth
        role
      }
    }
  `;
  const res = (async () => {
    try {
      return (await request(USER_ACCOUNT_GRAPHQL_URL, query)).getUserPasswordResetRequest;
    } catch (e) {
      return `${e}`;
    }
  })();
  return res;
};

export const getUserPasswordResetInput = async (args: { pass: string; auth: string }): Promise<User> => {
  const query = gql`
    query {
      getUserPasswordResetInput(
        pass: "${args.pass}"
        auth: "${args.auth}"
      ) {
        id
        name
        email
        auth
        role
      }
    }
  `;
  const res = (async () => {
    try {
      return (await request(USER_ACCOUNT_GRAPHQL_URL, query)).getUserPasswordResetInput;
    } catch (e) {
      return `${e}`;
    }
  })();
  return res;
};

export const getUserList = async (): Promise<User[]> => {
  const query = gql`
    query {
      getUserList {
        userList {
          id
          name
          email
          auth
          role
        }
      }
    }
  `;

  try {
    const res = (await request(USER_GRAPHQL_URL, query)).getUserList.userList;
    return sortBy(res, ['name'], ['asc']);
  } catch (e) {
    console.error(`ユーザ情報が取得できませんでした\n${e}`);
    return [];
  }
};

export const updateUserRole = async (args: { id: number; role: string }): Promise<User> => {
  const query = gql`
    mutation {
      updateUserRole(
        id: ${args.id}
        role: "${args.role}"
      ){
        id
        name
        email
        auth
        role
      }
    }
    `;
  const res = (async () => {
    try {
      return (await request(USER_GRAPHQL_URL, query)).updateUserRole;
    } catch (e) {
      return `${e}`;
    }
  })();
  return res;
};

export const sendEmailWithAccessCode = async (args: MutationSendEmailWithAccessCodeArgs): Promise<boolean> => {
  if (!args.userIdList) {
    return false;
  }
  const query = gql`
    mutation {
      sendEmailWithAccessCode(
        title: "${args.title}"
        bodyWithAccessCode: "${args.bodyWithAccessCode}"
        userIdList: [${args.userIdList.join(',')}]
      ){
        isSendingAccessCode
      }
    }
    `;
  const res = (async () => {
    try {
      return (await request(USER_GRAPHQL_URL, query)).sendEmailWithAccessCode;
    } catch (e) {
      return `${e}`;
    }
  })();
  return res;
};
