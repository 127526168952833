import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoDataForm = _resolveComponent("VideoDataForm")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.state.refresh)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_VideoDataForm, {
            courseList: _ctx.state.courseList,
            stripePlanList: _ctx.state.stripePlanList,
            onChanged: _ctx.formChange
          }, null, 8, ["courseList", "stripePlanList", "onChanged"]),
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("label", {
                    class: _normalizeClass(_ctx.$style.input_label)
                  }, [
                    _createElementVNode("input", {
                      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.videoOnChange && _ctx.videoOnChange(...args))),
                      type: "file"
                    }, null, 32),
                    _createTextVNode("動画を選択 ")
                  ], 2)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("video", {
                    id: "videoPreview",
                    class: _normalizeClass(_ctx.$style.preview),
                    src: _ctx.state.videoPreview
                  }, null, 10, _hoisted_2)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("label", {
                    class: _normalizeClass(_ctx.$style.input_label)
                  }, [
                    _createElementVNode("input", {
                      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.imgOnChange && _ctx.imgOnChange(...args))),
                      type: "file"
                    }, null, 32),
                    _createTextVNode(" サムネイルを選択 ")
                  ], 2)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    id: "imgPreview",
                    class: _normalizeClass(_ctx.$style.preview),
                    src: _ctx.state.imgPreview
                  }, null, 10, _hoisted_3)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.$style.submit_button, _ctx.$style.el_button]),
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.upload && _ctx.upload(...args)))
          }, "アップロード", 2)
        ], 512)), [
          [_vShow, !_ctx.state.inputDisable]
        ])
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.submitStatus, (status) => {
        return (_openBlock(), _createElementBlock("p", { key: status }, _toDisplayString(status), 1))
      }), 128)),
      _createVNode(_component_el_button, {
        onClick: _ctx.fixStatus,
        class: _normalizeClass(_ctx.$style.el_button)
      }, {
        default: _withCtx(() => [
          _createTextVNode("修正して投稿する")
        ]),
        _: 1
      }, 8, ["onClick", "class"]),
      _createVNode(_component_el_button, {
        onClick: _ctx.clearStatus,
        class: _normalizeClass(_ctx.$style.el_button)
      }, {
        default: _withCtx(() => [
          _createTextVNode("クリアして次の投稿へ")
        ]),
        _: 1
      }, 8, ["onClick", "class"])
    ], 512), [
      [_vShow, _ctx.state.inputDisable]
    ])
  ]))
}