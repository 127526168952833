import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _component_Back = _resolveComponent("Back")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.state.mode === 'notfound')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "動画が存在しません"))
      : _createCommentVNode("", true),
    (_ctx.state.mode === 'norelease')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "この動画は非公開です"))
      : _createCommentVNode("", true),
    (_ctx.state.mode === 'notAuthorized')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "閲覧権限がありません"))
      : _createCommentVNode("", true),
    (_ctx.state.mode === 'viewer')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.state.videoData.value), 1),
          _createVNode(_component_VideoPlayer, {
            id: _ctx.$style.videoPlayer,
            options: _ctx.videoOptions
          }, null, 8, ["id", "options"]),
          _createElementVNode("p", null, _toDisplayString(_ctx.state.videoData.description), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_button, { onClick: _ctx.historyBack }, {
      default: _withCtx(() => [
        _createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_Back)
          ]),
          _: 1
        }),
        _createTextVNode(" 戻る ")
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}