
import { defineComponent, reactive, PropType } from 'vue';
import { Stripe } from '@/../../@types/api';
import { PLAN_PERIOD } from '@/constants/constant';

export default defineComponent({
  name: 'SubscriptionModule',
  props: {
    data: {
      type: Object as PropType<{
        plan: Stripe;
        paidNow: boolean;
        status: string;
        accessCode?: string;
      }>,
      required: true,
    },
  },
  async setup(props) {
    const type = props.data.plan.type as
      | 'free_month'
      | 'free_unlimited'
      | 'onetime'
      | 'day'
      | 'week'
      | 'month'
      | 'year';
    const period = PLAN_PERIOD[type].display;

    const action = (() => {
      if (props.data.plan.stripePriceId) {
        if (props.data.accessCode) {
          return `/api/stripe/checkout/${props.data.plan.stripePriceId}/?accessCode=${props.data.accessCode}`;
        } else {
          return `/api/stripe/checkout/${props.data.plan.stripePriceId}`;
        }
      } else {
        if (props.data.accessCode) {
          return `/stripe/confirmation/${props.data.plan.id}/?accessCode=${props.data.accessCode}`;
        } else {
          return `/stripe/confirmation/${props.data.plan.id}`;
        }
      }
    })();

    const state = reactive({
      action,
      period,
    });

    const formSubmit = () => {
      if (props.data.status !== 'enable') {
        return;
      }
      window.location.href = state.action;
    };

    return {
      state,
      props,
      formSubmit,
    };
  },
});
