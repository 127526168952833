
import { defineComponent, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { BUTTON_COLOR, STRIPE_CUSTOMER_PORTAL_URL, USER_ROLE } from '@/constants/constant';
import { getUserBySession, updateUsername, updateUserEmail, updateUserPass } from '@/api/user';
import { getStripeListUsingAndUnUsing } from '@/api/stripe';
import { getReceiptList } from '@/api/receipt';
import * as notification from '@/components/_lib/notification';

export default defineComponent({
  name: 'MyPage',
  async setup() {
    const router = useRouter();
    const user = await getUserBySession();
    const state = reactive({
      user,
      edit: {
        name: { isEditable: false, value: user.name },
        email: { isEditable: false, value: user.email },
        pass: { isEditable: false, value: '', confirmValue: '' },
      },
      stripePlanList: await getStripeListUsingAndUnUsing(),
      receiptList: await getReceiptList({ userId: Number(user.id) }),
    });
    const refresh = async () => {
      const newUser = await getUserBySession();
      state.user = newUser;
      state.edit = {
        name: { isEditable: false, value: newUser.name },
        email: { isEditable: false, value: newUser.email },
        pass: { isEditable: false, value: '', confirmValue: '' },
      };
    };

    const submitEditName = async () => {
      const editedUser = await updateUsername({ id: Number(state.user.id), name: String(state.edit.name.value) });
      if (typeof editedUser === 'string') {
        notification.error({ title: 'エラー', message: '更新失敗しました' });
        return;
      }
      notification.success({ title: '更新成功', message: '更新しました' });
      refresh();
    };
    const submitEditEmail = async () => {
      const editedUser = await updateUserEmail({ id: Number(state.user.id), email: String(state.edit.email.value) });
      if (typeof editedUser === 'string') {
        notification.error({ title: 'エラー', message: '更新失敗しました' });
        return;
      }
      notification.info({ title: 'メールを送りました', message: 'メールを確認してください' });
      refresh();
    };
    const submitEditPass = async () => {
      if (state.edit.pass.value !== state.edit.pass.confirmValue) {
        notification.error({ title: 'エラー', message: 'パスワードが一致しません' });
        return;
      }
      const editedUser = await updateUserPass({ id: Number(state.user.id), pass: String(state.edit.pass.value) });
      if (typeof editedUser === 'string') {
        notification.error({ title: 'エラー', message: '更新失敗しました' });
        return;
      }
      notification.success({ title: '更新成功', message: '更新しました' });
      refresh();
    };

    const getPlanFromPlanId = (planId: number) => {
      const matchPlan = state.stripePlanList.filter((plan) => {
        return plan.id === planId;
      })[0];
      return matchPlan;
    };

    const dateForString = (date: Date) => {
      const d = new Date(date);
      return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
    };

    return {
      state,
      router,
      BUTTON_COLOR,
      STRIPE_CUSTOMER_PORTAL_URL,
      submitEditName,
      submitEditEmail,
      submitEditPass,
      getPlanFromPlanId,
      dateForString,
      USER_ROLE,
    };
  },
});
