
import { defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { VideoData } from '@/../../@types/api';
import { publicBucketPath } from '@/utils/publicBucketPath';

export default defineComponent({
  name: 'Thumbnail',
  props: {
    videoData: {
      type: Object as PropType<VideoData>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const imgSrc = publicBucketPath(String(props.videoData.imgSrc));
    const handleSelect = () => {
      router.push(`/video/view/${props.videoData.videoViewSrc}`);
    };
    return { handleSelect, imgSrc };
  },
});
