import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import Home from '@/views/Home.vue';
import UserCreate from '@/views/User/UserCreate.vue';
import NotFound from '@/views/404.vue';
import Forbidden from '@/views/403.vue';
import Search from '@/views/Video/Search.vue';
import SearchSP from '@/views/Video/SearchSP.vue';
import View from '@/views/Video/View.vue';
import Subscription from '@/views/Video/Subscription.vue';
import Management from '@/views/Video/Management.vue';
import StripeSuccess from '@/views/Stripe/Success.vue';
import StripeCancel from '@/views/Stripe/Cancel.vue';
import StripeConfirmation from '@/views/Stripe/Confirmation.vue';
import StripeSuccessActivate from '@/views/Stripe/SuccessActivate.vue';
import UserAuthSuccess from '@/views/User/Auth/Success.vue';
import UserAuthError from '@/views/User/Auth/Error.vue';
import MyPage from '@/views/User/MyPage.vue';
import UserEmailEditSuccess from '@/views/User/EmailEdit/Success.vue';
import UserEmailEditError from '@/views/User/EmailEdit/Error.vue';
import PasswordReset from '@/views/User/PasswordReset/PasswordReset.vue';
import PasswordResetInput from '@/views/User/PasswordReset/Input.vue';
import PasswordResetError from '@/views/User/PasswordReset/Error.vue';
import Law from '@/views/Law.vue';

import { NO_LOGIN_ALLOW_URL_LIST, ONLY_ADMIN_URL_LIST, USER_ROLE } from '@/constants/constant';
import { getUserBySession } from '@/api/user';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },

  {
    path: '/user/create',
    name: 'UserCreate',
    component: UserCreate,
  },

  {
    path: '/video/search',
    name: 'Search',
    component: Search,
  },

  {
    path: '/video/search/sp',
    name: 'SearchSP',
    component: SearchSP,
  },

  {
    path: '/video/view/:videoviewsrc',
    name: 'View',
    component: View,
  },

  {
    path: '/video/subscription',
    name: 'Subscription',
    component: Subscription,
  },

  {
    path: '/video/management',
    name: 'Management',
    component: Management,
  },
  {
    path: '/stripe/success',
    name: 'StripeSuccess',
    component: StripeSuccess,
  },
  {
    path: '/stripe/cancel',
    name: 'StripeCancel',
    component: StripeCancel,
  },
  {
    path: '/stripe/confirmation/:planid',
    name: 'StripeConfirmation',
    component: StripeConfirmation,
  },
  {
    path: '/stripe/successactivate',
    name: 'StripeSuccessActivate',
    component: StripeSuccessActivate,
  },
  {
    path: '/user/auth/success',
    name: 'UserAuthSuccess',
    component: UserAuthSuccess,
  },
  {
    path: '/user/auth/error/:errorcode',
    name: 'UserAuthError',
    component: UserAuthError,
  },
  {
    path: '/user/mypage',
    name: 'MyPage',
    component: MyPage,
  },
  {
    path: '/user/emailedit/success',
    name: 'UserEmailEditSuccess',
    component: UserEmailEditSuccess,
  },
  {
    path: '/user/emailedit/error/:errorcode',
    name: 'UserEmailEditError',
    component: UserEmailEditError,
  },
  {
    path: '/user/paswordreset',
    name: 'PasswordReset',
    component: PasswordReset,
  },
  {
    path: '/user/passwordreset/input/:auth',
    name: 'UserPasswordResetInput',
    component: PasswordResetInput,
  },
  {
    path: '/user/passwordreset/error/:errorcode',
    name: 'PasswordResetError',
    component: PasswordResetError,
  },
  {
    path: '/403',
    name: 'Forbidden',
    component: Forbidden,
  },
  {
    path: '/law',
    name: 'Law',
    component: Law,
  },
  { path: '/:catchAll(.*)', component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach(async (to) => {
  const ifNoLoginAllowPage = NO_LOGIN_ALLOW_URL_LIST.some((url) => {
    return to.path.match(`^${url}$`);
  });

  const ifOnlyAdminPage = ONLY_ADMIN_URL_LIST.some((url) => {
    return to.path.match(`^${url}$`);
  });

  const user = await (async () => {
    try {
      return await getUserBySession();
    } catch {
      return;
    }
  })();

  if (ifNoLoginAllowPage) {
    // allow
  } else if (ifOnlyAdminPage) {
    if (user?.role !== USER_ROLE.ADMIN) {
      // router.push だと前ページのエラー引き摺って画面がでないことがあるので location.href
      window.location.href = '/403';
    }
  } else {
    if (!user) {
      window.location.href = '/403';
    }
  }
});

export default router;
