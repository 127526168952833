
import { defineComponent, reactive, PropType } from 'vue';
import { Course, Stripe } from '@/../../@types/api';

export default defineComponent({
  name: 'ManagementVideoEdit',
  props: {
    courseList: {
      type: Array as PropType<Course[]>,
      required: true,
    },
    stripePlanList: {
      type: Array as PropType<Stripe[]>,
      required: true,
    },
    defaultInput: {
      type: Object as PropType<{
        id: number;
        value: string;
        description: string;
        videoViewSrc: string;
        courseId: number;
        release: boolean;
      }>,
      required: false,
      default: () => {
        return {
          value: '',
          description: '',
          videoViewSrc: '',
          courseId: -1,
          release: true,
        };
      },
    },
    defaultInputPlan: {
      type: Array as PropType<boolean[]>,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  async setup(props, context) {
    const state = reactive({
      videoDataRaw: props.defaultInput,
      selectPlan: props.defaultInputPlan,
      courseList: props.courseList,
      stripePlanList: props.stripePlanList,
    });

    const changed = () => {
      context.emit('changed', {
        videoDataRaw: state.videoDataRaw,
        selectPlan: state.selectPlan,
      });
    };
    return {
      state,
      changed,
    };
  },
});
