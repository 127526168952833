
import { defineComponent, reactive } from 'vue';
import * as notification from '@/components/_lib/notification';
import { getStripeList, upsertStripe, destroyStripe } from '@/api/stripe';
import { Stripe } from '@/../../@types/api';
import { BUTTON_COLOR } from '@/constants/constant';

export default defineComponent({
  name: 'ManagementStripe',
  async setup(props, context) {
    const state = reactive<{
      createForm: {
        id: number;
        title: string;
        description: string;
        amount: number;
        stripePriceId: string | null;
        stripeProductId: string | null;
        type: string;
        target: string;
      };
      guideDialog: boolean;
      stripeList: Stripe[];
      planTypeList: Array<{ display: string; value: string; disabled: boolean }>;
      targetList: Array<{ display: string; value: string }>;
      amountDisable: boolean;
    }>({
      createForm: {
        id: -1,
        title: '',
        description: '',
        amount: 0,
        stripePriceId: '',
        stripeProductId: '',
        type: '',
        target: '',
      },
      guideDialog: false,
      stripeList: await getStripeList(),
      planTypeList: [
        { display: '無料-一ヶ月有効', value: 'free_month', disabled: false },
        { display: '無料-無期限', value: 'free_unlimited', disabled: false },
        { display: '買い切り', value: 'onetime', disabled: false },
        { display: '毎日課金', value: 'day', disabled: false },
        { display: '毎週課金', value: 'week', disabled: false },
        { display: '毎月課金', value: 'month', disabled: false },
        { display: '毎年課金', value: 'year', disabled: false },
      ],
      targetList: [
        { display: '誰でも購入可能', value: 'public' },
        { display: 'アクセスコードが必要', value: 'accessCode' },
        { display: '初回加入のみ', value: 'beginner' },
        { display: '年間プラン加入者のみ', value: 'subscriber' },
      ],
      amountDisable: false,
    });

    window.onkeyup = (e: KeyboardEvent) => {
      if (!e.ctrlKey || e.key != 'i') {
        return;
      }
      console.log('showing guide...');
      state.guideDialog = true;
    };

    const refresh = async () => {
      state.stripeList = await getStripeList();
    };

    const formCrear = async () => {
      state.createForm.id = -1;
      state.createForm.title = '';
      state.createForm.description = '';
      state.createForm.amount = 0;
      state.createForm.stripePriceId = '';
      state.createForm.stripeProductId = '';
      state.createForm.type = '';
      state.createForm.target = '';
      state.amountDisable = false;
      for (const i in state.planTypeList) {
        state.planTypeList[i].disabled = false;
      }
    };

    const upsertSubmit = async () => {
      if (!state.amountDisable && state.createForm.amount <= 49) {
        notification.error({ title: '金額エラー', message: '有料のプランの金額は 50円 以上を設定してください' });
        return;
      }

      const res = await (async () => {
        try {
          return await upsertStripe(state.createForm);
        } catch (e) {
          return `${e}`;
        }
      })();
      if (typeof res === 'object' && res !== null) {
        notification.success({ title: 'success', message: '作成しました' });
        context.emit('refresh');
        formCrear();
      } else {
        notification.error({ title: 'error', message: '失敗しました' });
      }
    };

    const edit = async (stripe: Stripe) => {
      state.createForm.id = Number(stripe.id);
      state.createForm.title = String(stripe.title);
      state.createForm.description = String(stripe.description);
      state.createForm.amount = Number(stripe.amount);
      state.createForm.stripePriceId = stripe.stripePriceId ? String(stripe.stripePriceId) : '';
      state.createForm.stripeProductId = stripe.stripeProductId ? String(stripe.stripeProductId) : '';
      state.createForm.type = String(stripe.type);
      state.createForm.target = String(stripe.target);

      if (stripe.type === 'free_month' || stripe.type === 'free_unlimited') {
        state.amountDisable = true;
        for (const i in state.planTypeList) {
          if (state.planTypeList[i].value.match(/free_month|free_unlimited/)) {
            state.planTypeList[i].disabled = false;
          } else {
            state.planTypeList[i].disabled = true;
          }
        }
      } else {
        state.amountDisable = false;
        for (const i in state.planTypeList) {
          if (!state.planTypeList[i].value.match(/free_month|free_unlimited/)) {
            state.planTypeList[i].disabled = false;
          } else {
            state.planTypeList[i].disabled = true;
          }
        }
      }
    };

    const destroy = async (stripe: Stripe) => {
      try {
        const data = stripe.stripePriceId
          ? { id: Number(stripe.id), stripeProductId: String(stripe.stripeProductId) }
          : { id: Number(stripe.id) };
        const res = await destroyStripe(data);
        if (res === null) {
          throw new Error('destory faild');
        }
        notification.success({ title: 'success', message: '削除しました' });
      } catch {
        notification.error({ title: 'error', message: '失敗しました' });
      }
      context.emit('refresh');
      formCrear();
    };

    const planTypeSelect = async (planType: string) => {
      if (planType === 'free_month' || planType === 'free_unlimited') {
        state.createForm.amount = 0;
        state.amountDisable = true;
      } else {
        state.amountDisable = false;
      }
    };

    const getTypeDisplay = (type: string): string => {
      return state.planTypeList.filter((plan) => {
        return !!(plan.value === type);
      })[0].display;
    };

    const getTargetDisplay = (targetVal: string): string => {
      return state.targetList.filter((target) => {
        return !!(target.value === targetVal);
      })[0].display;
    };

    return {
      state,
      refresh,
      upsertSubmit,
      formCrear,
      edit,
      destroy,
      planTypeSelect,
      getTypeDisplay,
      getTargetDisplay,
      BUTTON_COLOR,
    };
  },
});
